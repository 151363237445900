<template>
  <section id="StadiumTours">
    <div class="underlay"></div>
    <div class="overlay"></div>
    <div class="tours-image">
      <img
        class="stadiumTourBg"
        src="@/assets/images/Experiences/bg-tours.jpg"
        alt="tours background"
      />
      <!-- <div class="tour-A icons" :class="{active: active1}" @click="active1 = true, active2 = false, active3 = false, active4 = false, active5 = false">
        <svg width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#FFF" fill-rule="evenodd"><path d="M21.438 18.515l-6.519-6.518a9.73 9.73 0 01-2.922 2.922l6.518 6.518a2.066 2.066 0 102.923-2.922"/><path d="M8.266 14.466a6.206 6.206 0 01-6.2-6.2c0-3.419 2.781-6.2 6.2-6.2s6.2 2.781 6.2 6.2-2.781 6.2-6.2 6.2m8.266-6.2A8.266 8.266 0 100 8.266a8.266 8.266 0 0016.533 0"/></g></svg>
      </div>
      <div class="tour-B icons" :class="{active: active2}" @click="active2 = true, active1 = false, active3 = false, active4 = false, active5 = false">
        <svg width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#FFF" fill-rule="evenodd"><path d="M21.438 18.515l-6.519-6.518a9.73 9.73 0 01-2.922 2.922l6.518 6.518a2.066 2.066 0 102.923-2.922"/><path d="M8.266 14.466a6.206 6.206 0 01-6.2-6.2c0-3.419 2.781-6.2 6.2-6.2s6.2 2.781 6.2 6.2-2.781 6.2-6.2 6.2m8.266-6.2A8.266 8.266 0 100 8.266a8.266 8.266 0 0016.533 0"/></g></svg>
      </div>
      <div class="tour-C icons" :class="{active: active3}" @click="active3 = true, active1 = false, active2 = false, active4 = false, active5 = false">
        <svg width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#FFF" fill-rule="evenodd"><path d="M21.438 18.515l-6.519-6.518a9.73 9.73 0 01-2.922 2.922l6.518 6.518a2.066 2.066 0 102.923-2.922"/><path d="M8.266 14.466a6.206 6.206 0 01-6.2-6.2c0-3.419 2.781-6.2 6.2-6.2s6.2 2.781 6.2 6.2-2.781 6.2-6.2 6.2m8.266-6.2A8.266 8.266 0 100 8.266a8.266 8.266 0 0016.533 0"/></g></svg>
      </div>
      <div class="tour-D icons" :class="{active: active4}" @click="active4 = true, active1 = false, active2 = false, active3 = false, active5 = false">
        <svg width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#FFF" fill-rule="evenodd"><path d="M21.438 18.515l-6.519-6.518a9.73 9.73 0 01-2.922 2.922l6.518 6.518a2.066 2.066 0 102.923-2.922"/><path d="M8.266 14.466a6.206 6.206 0 01-6.2-6.2c0-3.419 2.781-6.2 6.2-6.2s6.2 2.781 6.2 6.2-2.781 6.2-6.2 6.2m8.266-6.2A8.266 8.266 0 100 8.266a8.266 8.266 0 0016.533 0"/></g></svg>
      </div>-->
      <StadiumToursPin class="pins" :pinState="pinState"/>
    </div>
    <div v-if="pinState.tourA" class="description">
      <h1>
        Raiders Locker Room
        <div class="divider"></div>
      </h1>
      <p>With special "VIP access" of this state-of-the-art locker room, there's a moment of magic when Coach Jon Gruden delivers a fiery pre-game pep talk: "Play like hell on every play and find a way to win...because that's what Raiders do."</p>
    </div>
    <div v-if="pinState.tourB" class="description">
      <h1>
        Post-Game Interview Room
        <div class="divider"></div>
      </h1>
      <p>A legendary Raider Hall-of-Famer gives every guest a unique, visceral sense of what it means to be a Raider. You can feel the fire that burns brightest in every Raider: the will to win. Other Raider legends share just how special it is to be part of the silver-and-black.</p>
    </div>
    <div v-if="pinState.tourC" class="description">
      <h1>
        The Field
        <div class="divider"></div>
      </h1>
      <p>Walk down the tunnel - just like the players - and onto the field at Allegiant Stadium. It's the opportunity of a lifetime, and a chance to get a taste of what it's like to be a member of the Silver and Black.</p>
    </div>
    <div v-if="pinState.tourD" class="description">
      <h1>
        Al Davis Memorial Torch
        <div class="divider"></div>
      </h1>
      <p>Get a one-of-a-kind Super Selfie of your visit to Allegiant Stadium. The photo captures the field, the torch, the dazzling skyline of Las Vegas, and you!</p>
    </div>
    <div v-if="pinState.default" class="description">
      <article>Experience Allegiant Stadium in an unexpected way with a custom behind the scenes tour built strategically for your event!</article>
      <h2>Click a tour stop above for more info</h2>
    </div>
  </section>
</template>

<script>
import StadiumToursPin from "@/components/sections/Experiences/StadiumTours/StadiumToursPin";

export default {
  name: "StadiumTours",
  components: {
    StadiumToursPin
  },
  data() {
    return {
      pinState:{
        tourA: false,
        tourB: false,
        tourC: false,
        tourD: false,
        default: true
      }
    };
  }
};
</script>

<style lang="scss" scoped>
#StadiumTours {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  @media (max-width: 993px) {
    height: calc((100vw / 16) * 9 + 50px);
  }

  .underlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 268px;
    height: 100%;
    background: rgba(0, 0, 0, 0.83);
    background-image: url("~@/assets/images/Carousel/generic-gallery-side-bg.jpg");
    z-index: 2;
    @media (max-width: 993px) {
      display: none;
    }
  }
  .overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 650px;
    width: calc(100% - 268px);
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.84) 28%,
      rgba(0, 0, 0, 0) 70%
    );
    z-index: 1;
    @media (max-width: 993px) {
      width: 100vw;
      height: calc((100vw / 16) * 9);
    }
  }
  .tours-image {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 1250px;
    margin-left: 268px;
    @media (max-width: 993px) {
      margin-left: 0;
      height: 90%;
    }
    .stadiumTourBg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      object-fit: cover;
      object-position: left;
      @media (max-width: $lg) {
        position: relative;
        height: auto;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
      }
      // @media (max-width: $md) {
      //   top: -400px;
      // }
      // @media (max-width: $sm) {
      //   top: -500px;
      //   width: 600px;
      // }
      //max-width: 1600px;
      // @media (max-width: 993px) {
      //   max-width: 100vw;
      // }
    }
    .pins{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      object-fit: cover;
      object-position: left;
      z-index: 1;
      @media (max-width: $lg) {
        bottom: inherit;
        top: -250px;
        left: 50px;
      }
      @media (max-width: $md) {
        top: -330px;
        left: 30px;
      }
      @media (max-width: $sm) {
        left: 20px;
        top: -380px;
        width: 600px;
      }
      @media (max-width: 430px) {
        top: -400px;
      }
      @media (max-width: 400px) {
        top: -420px;
      }
      @media (max-width: 380px) {
        left: 0px;
        top: -460px;
      }
    }
    // .icons {
    //   position: absolute;
    //   background-image: linear-gradient(180deg, #525252 0%, #1f1f1f 100%);
    //   border: 2px solid #ffffff;
    //   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    //   border-radius: 21px;
    //   height: 42px;
    //   width: 42px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   cursor: pointer;
    //   outline: none;
    //   z-index: 2;
    //   svg {
    //     outline: none;
    //   }
    // }
    // .tour-A {
    //   bottom: 292px;
    //   left: 153px;
    //   @media (max-width: 993px) {
    //     bottom: 22%;
    //     left: 10%;
    //   }
    // }
    // .tour-B {
    //   bottom: 347px;
    //   left: 103px;
    //   @media (max-width: 993px) {
    //     bottom: 40%;
    //     left: 2%;
    //   }
    // }
    // .tour-C {
    //   bottom: 262px;
    //   left: 722px;
    //   @media (max-width: 993px) {
    //     bottom: 22%;
    //     left: 37%;
    //   }
    // }
    // .tour-D {
    //   bottom: 448px;
    //   left: 474px;
    //   @media (max-width: 993px) {
    //     bottom: 45%;
    //     left: 25%;
    //   }
    // }
    // .active {
    //   background-image: linear-gradient(180deg, #ffd200 0%, #937900 100%);
    // }
  }
  .description {
    position: absolute;
    bottom: 49px;
    left: 301px;
    z-index: 2;
    @media (max-width: 993px) {
      left: 100px;
      bottom: 0px;
    }
    @media (max-width: $md) {
      left: 50px;
    }
    @media (max-width: $sm) {
      left: 20px;
      bottom: 0px;
    }
    h1 {
      font-family: "ArtegraSans-Bold";
      font-size: 35px;
      color: #ffffff;
      letter-spacing: 2.19px;
      line-height: 30px;
      text-transform: uppercase;
      height: 39px;
      width: fit-content;
      margin-bottom: 17px;
      @media (max-width: 993px) {
        font-size: 20px;
        letter-spacing: 1.25px;
        line-height: 20px;
        height: 20px;
      }
      .divider {
        background: #ffffff;
        box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.8);
        width: auto;
        height: 2px;
        margin-top: 12px;
        @media (max-width: 993px) {
          display: none;
        }
      }
    }
    h2 {
      font-family: "ArtegraSans-Bold";
      font-size: 17px;
      color: #ffffff;
      letter-spacing: 1.06px;
      line-height: 30px;
      text-transform: uppercase;
      margin: 15px 0 27px 0;
      @media (max-width: 993px) {
        margin-top: 10px;
      }
    }

    p {
      font-family: "ArtegraSans-Regular";
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 1.07px;
      line-height: 23px;
      width: 705px;
      height: 100px;
      @media (max-width: 993px) {
        width: 80vw;
      }
      @media (max-width: $lg) {
        font-size: 14px;
        line-height: 16px;
      }
      @media (max-width: $sm) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    article {
      font-family: "ArtegraSans-Regular";
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 1.07px;
      line-height: 23px;
      width: 705px;
      @media (max-width: 993px) {
        width: 100%;
      }
      @media (max-width: $sm) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
</style>